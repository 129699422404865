import React from 'react'
import { graphql } from 'gatsby'
import Content from '../components/Content.js'
import PageHeader from '../components/PageHeader'
import Layout from '../components/Layout'
// import Form from '../components/FormControlled'
import './VolPage.css'

// Export Template for use in CMS preview
export const VolPageTemplate = ({
  title,
  section1
}) => (
  <main className="VolPage">
    <section className="section2">
    <div className="container">
      <h2>Volunteer signup form</h2>
        <Content source={section1} />
      </div>
    </section>
  </main>
)

const VolPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <VolPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)
export default VolPage

export const pageQuery = graphql`
  query VolPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        section1
      }
    }
  }
`
